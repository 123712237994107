import {React, useState} from 'react';
import './App.css';
import LandingPage from './pages/LandingPage';
import Header from './components/Header';
import Footer from './components/Footer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import SignupOverlay from './pages/SignupOverlay';
import TeamPage from './pages/TeamPage';

function App() {
  const [signupOpen, setSignupOpen] = useState(false)

  return (
    <ChakraProvider>
      <div className="App">
        <Router>
          <SignupOverlay props={{signupOpen: signupOpen, setSignupOpen: setSignupOpen}} />
          <Header props={{setSignupOpen: setSignupOpen}}/>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/learn" element={<LandingPage />} />
            <Route path="/about" element={<LandingPage />} />
            <Route path="/careers" element={<LandingPage />} />
            <Route path="/team" element={<TeamPage />} />
          </Routes>
          <Footer/>
        </Router>
      </div>
    </ChakraProvider>
  );
}

export default App
//export default App; //Use when working on post-auth app
//export default withAuthenticator(App); //Use in prod/when working on auth page
