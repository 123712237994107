import Box from "../components/Box.js";
import COLOR from "../colors.js"
import MockupIncome from "./LandingPageComponents.js/MockupIncome.js";
import MockupMaxIncome from "./LandingPageComponents.js/MockupMaxIncome.js";
import IntroText from "./LandingPageComponents.js/IntroText.js";


function LandingPage() {
    return (
        <Box
            height='100%'
            width='100%'
            display='flex'
            flexDirection='column'
            bgcolor={COLOR.white}
            paddingTop="100px"
            paddingBottom="100px"
        >
            <IntroText />
            <MockupIncome />
            <MockupMaxIncome />
        </Box>
    )
}

export default LandingPage