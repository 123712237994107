import Box from "./Box";
import COLOR from "../colors";
import styled from "styled-components"

const Body = styled.text`
    font-family: Montserrat;
    padding-bottom: 5px;
`

const Title = styled.text`
    font-family: Montserrat;
    font-size: 1.3em;
    padding-bottom: 20px;
`


function Footer() {
    return (
        <Box
            width="100%"
            height="300px"
            bgcolor={COLOR.grey1}
            justifyContent="space-between"
            display="flex"
            flexDirection="column"
            alignItems="column"
        >
            <Box
                display="flex"
                flexDirection="row"
                padding="50px 0px 0px 200px"
                alignItems="flex-start"
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    paddingLeft="5%"
                    alignItems="flex-start"
                >
                    <Title> General </Title>
                    <a
                        href="mailto:tobiasworledge@berkeley.edu"
                        style={{
                            paddingBottom: "5px",
                            fontFamily: "Montserrat"
                        }}
                    >
                        Questions?
                    </a>
                    <a
                        href="https://www.linkedin.com/company/usecycle/"
                        style={{
                            paddingBottom: "5px",
                            fontFamily: "Montserrat"
                        }}
                    >
                        LinkedIn
                    </a>
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    paddingLeft="20%"
                    alignItems="flex-start"
                >
                    <Title>Products</Title>
                    <Body>Personal Finance App</Body>
                    <Body>How to start</Body>
                    <Body>Our Plans</Body>
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    paddingLeft="20%"
                    alignItems="flex-start"
                >
                    <Title>Who we are</Title>
                    <Body>Meet the team</Body>
                    <Body>Our Values</Body>
                    <Body>Careers</Body>
                    <Body>Blog</Body>
                </Box>
            </Box>
            <Box
                alignSelf="center"
                paddingBottom="10px"
            >
                <body
                    style={{
                        fontSize: '0.8em',
                        backgroundColor: COLOR.grey1
                    }}
                >
                    Copyright © 2022 Cycle
                </body>
            </Box>
        </Box>
    )
}

export default Footer