import {Box, Image, HStack, VStack, Heading} from '@chakra-ui/react'
import details from "../../images/mockups/mockup_details.svg"

const MockupIncome = () => {
    return (
        <>
        <HStack w="100%">
            <Box >
                <Image marginRight='100' marginLeft='250' marginTop='10' htmlWidth='330px' src={details}/>  
            </Box>
            <VStack align="left" spacing="10px">
                <Heading align="left" fontWeight="bold" fontSize={{md: '4xl', sm: '3xl', base: '2xl'}} > 
                    Do work you love
                </Heading>
                <Heading align="left" fontWeight="normal" fontSize={{md: '4xl', sm: '3xl', base: '2xl'}} > 
                    Accept convenient jobs
                </Heading>
                <Heading align="left" fontWeight="normal" fontSize={{md: '4xl', sm: '3xl', base: '2xl'}} > 
                    Reduce idle time
                </Heading>
                <Heading align="left" fontWeight="normal" fontSize={{md: '4xl', sm: '3xl', base: '2xl'}} > 
                    Maximize your pay
                </Heading>
            </VStack>
        </HStack>
    </>
    )
}

export default MockupIncome