import listings from "../../images/mockups/mockup_listings.svg"
import {Box, Image, HStack, Heading, VStack, Text} from '@chakra-ui/react'

const IntroText = () => {
    return (
        <>
        <HStack w="100%">
            <VStack marginLeft='100' w="50%" align="left" spacing="10">
                <VStack align="center" spacing="0">
                    <Heading fontWeight="light" fontSize={{md: '5xl', sm: '3xl', base: '2xl'}} > 
                        Job search for gig-workers is broken.
                    </Heading>
                    <Heading paddingBottom="8" bgGradient="linear(-90deg, #35C6F4, #6a82fb)" bgClip="text" fontSize={{md: '5xl', sm: '4xl', base: '3xl'}} fontWeight="semibold"> 
                        We're fixing it
                    </Heading>
                    <Text maxW="750px" align="center" fontSize={{sm: "normal", md:'xl'}} color="gray.700"> 
                        Automatically find, compare, and accept gig jobs from multiple providers on one simple platform.
                    </Text>
                </VStack>
            </VStack>
            <Box marginLeft='100'>
                <Image marginRight='100' marginLeft='150' marginTop='10' htmlWidth='330px' src={listings}/>  
            </Box>
        </HStack>
    </>
    )
}

export default IntroText