import { Box } from "@mui/system";
import COLOR from "../colors";
import TobiasWorledgePFP from "../images/profile-pictures/tobias_worledge_pfp.jpg"
import OliverJohanssonPFP from "../images/profile-pictures/oliver_johansson_pfp.jpg"
import LukasBarthPFP from "../images/profile-pictures/lukas_barth_pfp.jpg"
import UCLaunch from "../images/logos/uclaunch.png"
import BerkeleyStEP from "../images/logos/berkeleystep.png"
import {Heading, Text} from '@chakra-ui/react'

function TeamPage() {
    return (
        <Box
            width='100%'
            display='flex'
            flexDirection='column'
            bgcolor={COLOR.white}
            padding="100px 0px 100px 0px"
            justifyContent="flex-start"
        >
            <Heading
                fontWeight="semi-bold"
                fontSize={{md: '4xl', sm: '2xl', base: '1xl'}}
                bgGradient="linear(-20deg, #35C6F4 45%, #6a82fb 55%)"
                bgClip="text"
                margin="10px 0px 30px 0px"
            > 
                Meet our team
            </Heading>
            <Box
                display='flex'
                flexDirection='row'
                justifyContent="space-evenly"
            >
                <Box
                    display='flex'
                    flexDirection='column'
                    alignItems='center'
                >
                    <img
                        src={OliverJohanssonPFP}
                        alt="OliverJohanssonPFP"
                        style={{
                            borderRadius: "100%",
                            width: "210px",
                            marginBottom: "15px"
                        }}
                    /> 
                    <Text
                        fontWeight="heavy"
                        fontSize={{sm: "normal", md:'xl'}}
                    >
                        Oliver Johansson
                    </Text>
                    <Text
                        fontWeight="light"
                        fontSize={{sm: "normal", md:'1xl'}}
                        color={COLOR.darkGrey}
                    >
                        CTO, Co-Founder<br/>Berkeley CS
                    </Text>
                </Box>
                <Box
                    display='flex'
                    flexDirection='column'
                    alignItems='center'
                >
                    <img
                        src={TobiasWorledgePFP}
                        alt="TobiasWorledgePFP"
                        style={{
                            borderRadius: "100%",
                            width: "210px",
                            marginBottom: "15px"
                        }}
                    /> 
                    <Text
                        fontWeight="heavy"
                        fontSize={{sm: "normal", md:'xl'}}
                    >
                        Tobias Worledge
                    </Text>
                    <Text
                        fontWeight="light"
                        fontSize={{sm: "normal", md:'1xl'}}
                        color={COLOR.darkGrey}
                    >
                        CEO, Co-Founder<br/>Berkeley CS
                    </Text>
                </Box>
                {/* <Box
                    display='flex'
                    flexDirection='column'
                    alignItems='center'
                >
                    <img
                        src={LukasBarthPFP}
                        alt="LukasBarthPFP"
                        style={{
                            borderRadius: "100%",
                            width: "210px",
                            marginBottom: "15px"
                        }}
                    /> 
                    <Text
                        fontWeight="heavy"
                        fontSize={{sm: "normal", md:'xl'}}
                    >
                        Lukas Barth
                    </Text>
                    <Text
                        fontWeight="light"
                        fontSize={{sm: "normal", md:'1xl'}}
                        color={COLOR.darkGrey}
                    >
                        COO, Co-Founder<br/>MBA Berkeley Haas
                    </Text>
                </Box> */}
            </Box>
            <Heading
                fontWeight="semi-bold"
                fontSize={{md: '4xl', sm: '2xl', base: '1xl'}}
                marginBottom="30px"
                bgGradient="linear(-150deg, #35C6F4 45%, #6a82fb 55%)"
                bgClip="text"
                margin="80px 0px -50px 0px"
            >
                Backed By
            </Heading>
            <Box
                display='flex'
                flexDirection='row'
                justifyContent='space-evenly'
                alignItems="center"
                paddingBottom="50px"
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                >
                    <img
                        src={UCLaunch}
                        alt="UCLaunch"
                        style={{
                            width: "300px"
                        }}
                    />
                    <Text
                        marginTop="-105px"
                        color={COLOR.darkGrey}
                    >
                        Spring 2022 Cohort
                    </Text>
                </Box>
                {/* <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    paddingTop="115px"
                >
                    <img
                        src={BerkeleyStEP}
                        alt="BerkeleyStEP"
                        style={{
                            height: "60px"
                        }}
                    />
                    <Text
                        paddingTop='15px'
                        color={COLOR.darkGrey}
                    >
                        Spring 2022 Cohort
                    </Text>
                </Box> */}
            </Box>
        </Box>
    )
}

export default TeamPage