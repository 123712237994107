const COLOR = {
    white: "#fff",
    black: "#000",
    grey1: "#E6E6E6",
    grey2: "#8c8c8c",
    grey3: "#bfbfbf",
    darkGrey: "#2D3848",
    blue1: "#54D4FE",
    blue2: "#0084ff",
    blue3: "#78d2ff"
}

export default COLOR;