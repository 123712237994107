import logopng from "../images/logos/Logo.png"
import {motion} from 'framer-motion'
import { useState } from "react"
import {Flex, Text, Image, Button, Divider, Collapse} from '@chakra-ui/react'

const links = [
    {title: 'About', route: '/about'},
    {title: 'Learn', route: '/learn'},
    {title: 'Careers', link: '/careers', out: true},
    {title: 'Team', route: '/team', },

]

const WidthWrap = (props) => {

    // TODO add 100% vh mobile styling fix

    return(
    <Flex w="100vw" justifyContent="center" alignItems="center" bg={props.bg}>
        <Flex w="100%" maxW="1500px" bg={props.bg}>
            {props.children}
        </Flex>
    </Flex>
    )
}

const MiniLink = ({link}) => {

    return(
        <Flex marginLeft="6" marginRight="6" alignItems="center">
            {link.out ?
             <a href={link.link}>
                <motion.div whileHover={{y: -0.5, transition: {type: 'spring'}}}>
                    <Text fontSize="md" fontWeight="light"> {link.title} </Text>
                </motion.div>
            </a>
            :
            <a href={link.route}>
                <motion.div whileHover={{y: -0.5, transition: {type: 'spring'}}}>
                    <Text fontSize="md" fontWeight="light"> {link.title} </Text>
                </motion.div>
            </a>
            }
           
        </Flex>
    )
}


const MobileNavLink = ({link}) => {

    return(
        <>
            <Flex paddingLeft="10" marginBottom="2" marginTop="2" alignItems="center">
                <a href={link.route}>
                    <motion.div whileHover={{x: 4, transition: {type: 'spring'}}}>
                        <Text fontSize="xl" fontWeight="normal"> {link.title} </Text>
                    </motion.div>
                </a>
            </Flex>
            <Divider/>
        </>
    )
}

const MenuIcon = ({w, h, color}) => {
    return(
      <svg style={{cursor: 'pointer'}} width={w} height={h} viewBox="0 0 16 16" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M1 3H3V1H1V3Z" fill={color}></path><path fillRule="evenodd" clipRule="evenodd" d="M1 9H3V7H1V9Z" fill={color}></path><path fillRule="evenodd" clipRule="evenodd" d="M1 15H3V13H1V15Z" fill={color}></path><path fillRule="evenodd" clipRule="evenodd" d="M7 3H9V1H7V3Z"fill={color}></path><path fillRule="evenodd" clipRule="evenodd" d="M13 3H15V1H13V3Z" fill={color}></path><path fillRule="evenodd" clipRule="evenodd" d="M7 9H9V7H7V9Z" fill={color}></path><path fillRule="evenodd" clipRule="evenodd" d="M13 9H15V7H13V9Z" fill={color}></path><path fillRule="evenodd" clipRule="evenodd" d="M7 15H9V13H7V15Z" fill={color}></path><path fillRule="evenodd" clipRule="evenodd" d="M13 15H15V13H13V15Z" fill={color}></path></svg>
    )
  }

const Header = (props) => {

    const [drawerVisibility, setDrawerVisibility] = useState(false)

    return (
    <WidthWrap>
        <Flex pos="fixed" backgroundColor="white" flexDirection="column" w="100%" borderBottom="anitquewhite" borderWidth="thin" zIndex={100} top={0} left={0} >
            <Flex w="100%" padding={{md: "3", base: "4" }} paddingBottom="8" justifyContent="space-between" alignItems="center">
                <Image htmlWidth='120px' src={logopng}/>                
                
                <Flex display={{base: 'none', sm: "none", md:'flex'}} justifyContent="space-between">
                    {links.map((link) => <MiniLink link={link} />)}
                </Flex>

                <Flex display={{base: 'none', sm: "none", md:'flex'}} alignContent="center">
                    <MiniLink link={{route: 'faq', title: 'FAQ'}}/>
                        <Button onClick={() => props.props.setSignupOpen(true)} borderRadius="lg" _focus={{boxShadow: 'none'}} variant="solid" >
                                <Text marginLeft="3" marginRight="3"> Sign Up </Text>
                        </Button>
                    {/* <Flex justifyContent="center" borderRadius="lg" padding="1px" bgGradient ="linear(to-l, #0052D4, #6FB1FC)"  >
                        <Button onClick={() => setOpen(true)} borderRadius="lg" _hover={{bgGradient :"linear(to-l, #0052D4, #6FB1FC)"}} _focus={{boxShadow: 'none'}} variant="solid" background="black" >
                            <Text marginLeft="3" marginRight="3"> Sign Up </Text>
                        </Button>
                    </Flex> */}
                </Flex>

                <Flex display={{base: 'flex', sm: "flex", md:'none'}}>
                    <motion.div onClick={() => setDrawerVisibility(!drawerVisibility)} whileTap={{scale: 1}} style={{ display: 'flex'}} whileHover={{scale: 0.9, transition: {type: 'spring', delay: 0.1}}}>
                        <MenuIcon color="black" w="22" h="22"/>
                    </motion.div>
                </Flex>
            </Flex>
            <Flex  display={{base: 'flex', sm: "flex", md:'none'}} >
                <Collapse style={{width: '100%'}} in={drawerVisibility}>
                    {links.map((link) => <MobileNavLink link={link} />)}
                </Collapse>
            </Flex>
        </Flex>
        
       
    </WidthWrap>
    )
}

export default Header