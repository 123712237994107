import { Box } from "@mui/material"
import { Input, InputGroup, InputRightElement, Text, Button, useToast } from "@chakra-ui/react"
import COLOR from "../colors"
import { useState } from "react"
import { CheckIcon, CloseIcon } from "@chakra-ui/icons"
import axios from "axios"
import { CLIENT_API_KEY } from "../access_keys"

function verifySignup(contact, type) {
    var temp
    if (type === "phone") {
        temp = contact.match(/\d/g)
        return [temp != null && temp.length === 10, temp.join("")]
    } else if (type === "email") {
        temp = contact.match(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/)
        return [temp != null && temp.length === 1, contact]
    } else {
        return [false, undefined]
    }
}

function addSignup(contact, type, toast, setIsLoading) {
    const body = {
        "id": contact,
        "type": type,
        "contact": contact
    };
    const headers = {
        "Content-Type": "application/json",
        "apikey": CLIENT_API_KEY,
    };
    setIsLoading(true)
    axios.post('https://wz4gbew2r3.execute-api.us-west-1.amazonaws.com/Prod/addSignup', body, { headers })
        .then(response => {
            if (response.status === 200) {
                toast({
                    title: "Thanks for signing up!",
                    description: "We'll let you know when Cycle is ready for early access.",
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                })
            } else {
                toast({
                    title: "Oops, something went wrong!",
                    description: "We'll have this fixed ASAP.",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                })
            }
            setIsLoading(false)
        });
}

function SignupOverlay(props) {

    const [activeButton, setActiveButton] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [inputData, setInputData] = useState("")
    const toast = useToast()

    document.body.addEventListener('keyup', function(event) {
        if (event.key === "Escape") {
            props.props.setSignupOpen(false)
        }
    });
    return (
        <Box
            zIndex="1000"
            width="100vw"
            height="100vh"
            position="fixed"
            overflowY="hidden"
            visibility={props.props.signupOpen ? "visible" : "hidden"}
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
        >
            <Box
                width="400px"
                height="220px"
                bgcolor={COLOR.white}
                style={{
                    borderWidth: "thin",
                }}
                borderRadius="10px"
                borderColor={COLOR.grey1}
                boxShadow="0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)"
            >
                <Button
                    marginRight="-360px"
                    paddingBottom="-100px"
                    bgColor="transparent"
                    _hover={{bgColor: "transparent"}}
                    _focus={{ boxShadow: '0px' }}
                    _active={{ bgColor: "transparent" }}
                    borderRadius="50%"
                    onClick={() => props.props.setSignupOpen(false)}
                >
                    <CloseIcon
                        boxSize="10px"
                    />
                </Button>
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                >
                    <Text
                        fontWeight="medium"
                        fontSize="1.3em"
                    >
                        Signup for 
                    </Text>
                    <Text
                        padding="0px 0px 0px 5px"
                        fontWeight="medium"
                        fontSize="1.3em"
                        bgGradient="linear(90deg, #35C6F4, #6a82fb)"
                        bgClip="text"
                    >
                        Early Access
                    </Text>
                </Box>
                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                    padding="15px 10px 0px 10px"
                >
                    <Button
                        bgGradient={!activeButton ? "linear(20deg, #35C6F4, #6a82fb)" : null}
                        bgColor={!activeButton ? null : COLOR.grey1}
                        _hover={{
                            bgGradient: "linear(20deg, #35C6F4, #6a82fb)",
                            color: COLOR.white
                        }}
                        _focus={{ boxShadow: '0px' }}
                        color={!activeButton ? COLOR.white : COLOR.black}
                        variant='solid'
                        borderColor={COLOR.grey2}
                        onClick={() => setActiveButton(false)}
                        borderRadius="5px 0px 0px 5px"
                        width="150px"
                    >
                        Phone
                    </Button>
                    <Button
                        bgGradient={activeButton ? "linear(-100deg, #35C6F4, #6a82fb)" : null}
                        bgColor={activeButton ? null : COLOR.grey1}
                        _hover={{
                            bgGradient: "linear(-100deg, #35C6F4, #6a82fb)",
                            color: COLOR.white
                        }}
                        _focus={{ boxShadow: '0px' }}
                        color={activeButton ? COLOR.white : COLOR.black}
                        variant='solid'
                        borderColor={COLOR.grey2}
                        onClick={() => setActiveButton(true)}
                        borderRadius="0px 5px 5px 0px"
                        width="150px"
                    >
                        Email
                    </Button>
                </Box>
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    padding="20px 0px 0px 0px"
                >
                    <InputGroup>
                        <Input
                            placeholder={activeButton ? "Email" : "Phone Number"}
                            borderColor={COLOR.grey3}
                            width="300px"
                            pr="4.5em"
                            marginLeft="49px"
                            onChange={(event) => setInputData(event.target.value)}
                        />
                        <InputRightElement
                            width="4.5em"
                        >
                            <Button
                                width="35px"
                                height="35px"
                                marginLeft="-71px"
                                bgColor={COLOR.grey1}
                                _hover={{
                                    bgGradient: "linear(140deg, #35C6F4, #6a82fb)",
                                    color: COLOR.white
                                }}
                                zIndex="1"
                                _focus={{ boxShadow: '0px' }}
                                isLoading={isLoading}
                                onClick={
                                    () => {
                                        var temp = verifySignup(inputData, activeButton ? "email" : "phone")
                                        var inputCorrect = temp[0]
                                        var processedInput = temp[1]
                                        if (!inputCorrect) {
                                            toast({
                                                title: "Oops, something's not right",
                                                description: "Make sure you've entered your contact info correctly!",
                                                status: 'warning',
                                                duration: 5000,
                                                isClosable: true,
                                            })
                                            return
                                        }
                                        addSignup(processedInput, activeButton ? "email" : "phone", toast, setIsLoading)
                                    }
                                }
                            >
                                <CheckIcon
                                    boxSize="15px"
                                />
                            </Button>
                        </InputRightElement>
                    </InputGroup>
                </Box>

            </Box>
        </Box>
    )
}

export default SignupOverlay