import {Box, Image, Heading, VStack, HStack} from '@chakra-ui/react'
import map from "../../images/mockups/mockup_map.svg"

const MockupMaxIncome = () => {
    return (
        <>
        <HStack w="100%">
            <VStack w="50%" marginLeft='150' align="left" spacing="10px">
                <Heading align="left" fontWeight="bold" fontSize={{md: '4xl', sm: '3xl', base: '2xl'}} > 
                    Perfect your work strategy
                </Heading>
                <Heading align="left" fontWeight="normal" fontSize={{md: '4xl', sm: '3xl', base: '2xl'}} > 
                    Automate your job search process
                </Heading>
                <Heading align="left" fontWeight="normal" fontSize={{md: '4xl', sm: '3xl', base: '2xl'}} > 
                    Strategically pick hours and platforms
                </Heading>
            </VStack>
            <Box >
                <Image marginRight='150' marginLeft='100' marginTop='10' htmlWidth='330px' src={map}/>  
            </Box>
        </HStack>
    </>
    )
}

export default MockupMaxIncome